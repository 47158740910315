
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";
import { SET_USER_ERROR } from "../store/modules/user";
import { FIND_USER, GET_USER_WALLET_LOGS, SWITCH_USER_STATE } from "../store/types";

export default {
  methods: {
    getUser(id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_USER, id)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    setRatio(formData){
      if ( formData.ratio > 100 ){
        formData.ratio = 100;
      }else if ( formData.ratio < 1 ){
        formData.ratio = 1;
      }
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put(`/manager/user/${formData.id}/profit-ratio`, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_USER_ERROR);
          reject(response);
        })
      })
    },
    switchUserState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_USER_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getUserWalletLogs(id, page = 1, params = {start_time: '', end_time: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_USER_WALLET_LOGS, {id, page, params})
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
  }
}
