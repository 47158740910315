<template>
  <div class="user-list">
    <error :errors="userError" />
    <portlet fluidHeight>
      <template v-slot:body>
        <div class="kt-widget kt-widget--user-profile-3">
          <div class="kt-widget__top">
            <div class="kt-widget__media">
              <b-img :src="user.avatar" />
            </div>
            <div class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">
              {{ user.nickname }}
            </div>
            <div class="kt-widget__content">
              <div class="kt-widget__head">
                <div class="kt-widget__user">
                  <a href="javascript:;" class="kt-widget__username">
                    {{ user.nickname }}
                  </a>
                  <span class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success cursor-default mx-1">ۋاكالەتچى</span>
                  <span v-b-tooltip :title="`ئونىۋېرسال پايدا نىسپىتى`" v-if="user.profit_ratio" class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-info cursor-default mx-1">{{ user.profit_ratio.ratio }}%</span>
                  <span v-b-tooltip :title="`ئەزالىق پايدا نىسپىتى`" v-if="user.profit_ratio" class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-info cursor-default mx-1">{{ user.profit_ratio.membership_ratio }}%</span>
                </div>
              </div>
              <div class="kt-widget__subhead">
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="تېلفۇن نۇمۇرى"><i class="fa fa-mobile"></i> {{ user.phone }} </a>
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="ئەكىرگەن ئەزاسى"><i class="fa fa-user-friends"></i> {{ user.invitees_count }} نەپەر </a>
              </div>
              <div class="kt-widget__subhead" v-if="user.wallet">
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="ئۇمۇمى كىرىمى"><i class="fa fa-wallet"></i> {{ user.wallet.total || 0.00 }} ￥ </a>
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="قالدۇق سوممىسى"><i class="fa fa-credit-card"></i> {{ user.wallet.remainder || 0.00 }} ￥ </a>
              </div>
              <!--<div class="kt-widget__info">
                <div class="kt-widget__desc"></div>
              </div>-->
            </div>
          </div>
          <div class="kt-widget__bottom kt-hidden">
            <div class="kt-widget__item">
              <div class="kt-widget__icon">
                <i class="flaticon-piggy-bank"></i>
              </div>
              <div class="kt-widget__details">
                <span class="kt-widget__title">Earnings</span>
                <span class="kt-widget__value"><span>$</span>249,500</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </portlet>
    <b-overlay :show="formBusy">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
                class="mb-0 mr-2"
                v-model="userWalletLogsMeta.current_page"
                :total-rows="userWalletLogsMeta.total"
                :per-page="userWalletLogsMeta.per_page"
                @change="onChangePage"
            />
            <div class="actions">
              <b-row class="align-items-center d-none d-sm-flex">
                <b-col md="5" sm="5" >
                  <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                </b-col>
                <b-col md="5" sm="5" >
                  <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                </b-col>
                <b-col md="2" sm="2" >
                  <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                    يېڭىلاش
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="log in userWalletLogs" md="3" :key="log.id" sm="6">
          <portlet v-if="['article', 'article_episode', 'membership'].includes(log.logable_record)" title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar></template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! log.logable.orderer.avatar}" rounded="" height="50" :src="log.logable.orderer.avatar" alt="image" />
                    <div :class="{'kt-hidden': log.logable.orderer.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ log.logable.orderer.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable.orderer.nickname }}
                    </a>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'article'">سېتىۋالغان ئەسەردىن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'article_episode'"> سېتىۋالغان ئەسەر قىسمىدىن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'membership'"> سېتىۋالغان ئەزالىقتىن ئايرىلغان پايدا</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> ئايرىلغان پايدا </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
          <portlet v-if="['cashing'].includes(log.logable_record)" title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar></template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! log.logable.avatar}" rounded="" height="50" :src="log.logable.avatar" alt="image" />
                    <div :class="{'kt-hidden': log.logable.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ log.logable.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable.nickname }} (ئۆزى)
                    </a>
                    <span class="kt-widget__desc">نەقلەشتۈرگەن چىقىم خاتىرىسى</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <!--                  <div class="kt-widget__section"></div>-->
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> نەقلەشتۈرۈلگەن سومما </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
          <portlet v-if="['click_ad'].includes(log.logable_record)" title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar></template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! log.logable.avatar}" rounded="" height="50" :src="log.logable.avatar" alt="image" />
                    <div :class="{'kt-hidden': log.logable.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ log.logable.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable.nickname }}
                    </a>
                    <span class="kt-widget__desc">ئېلان چەككەنلىكى ئۈچۈن ئايرىلغان پايدا</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> ئايرىلغان پايدا </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="userWalletLogs.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                ھازىرچە مۇناسىۋەتلىك ئەزا ئ‍ۇچۇرى تېپىلمىدى
              </p>
            </div>
            <!--<div class="kt-callout__action">
              <b-button :to="{name: 'authors.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>-->
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }

  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
      padding-right: 1rem;
      padding-top: 0;
    }

    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
      padding: 0.5rem 0;
    }
  }

</style>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import Error from "../../../partials/widgets/Error";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  import userMixin from "../../../../mixins/userMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    components: { Error, Portlet, Datetime },
    mixins: [ userMixin, formBusyMixin ],
    computed: {
      ...mapGetters(['userError', 'user', 'userWalletLogs', 'userWalletLogsMeta']),
      userId(){
        let {id} = this.$route.params;
        return id;
      }
    },
    created() {
      this.getUser(this.userId);
      this.getUserWalletLogs(this.userId, 1);
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "/user/list" },
        { title: "بارلىق ئەزالار" },
        { title: "كىرىم چىقىم تەپسىلاتى" },
      ]);
    },
    data(){
      return {
        formData: {
          start_time: null,
          end_time: null,
        },
        formBusy: false,
      };
    },
    methods: {
      onChangePage(page = 1){
        this.getUserWalletLogs(this.userId, page, this.formData);
      },
      refreshList(){
        this.getUserWalletLogs(this.userId, 1, this.formData);
      },
    }
  }
</script>
